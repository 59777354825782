.ReactModal__Overlay {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.5) !important;
}

.ReactModal__Content {
    &.is-small {
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        max-height: 100vh;
        margin: 0 auto;
        padding: 1.5rem !important;
        border-color: transparent !important;
        border-radius: 4px !important;
        overflow-y: scroll !important;
        background-color: #fff;
        max-width: 900px;
        width: 100%;
    }

    &.no-overflow {
        overflow-y: initial !important;
    }
}

.ReactModal__Close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: 0;
    background-color: transparent;

    svg {
        width: 20px;
        height: 20px;
        fill: #fff;
    }
}

.react-modal__header,
.react-modal__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
}

.react-modal__header {
    margin-bottom: 1rem;

    .react-modal__heading {
        margin: 0;
    }
}